var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('van-popup',{class:_vm.blackTheme ? 'black' : '',style:({
      height: '100%',
      width: '78%',
      background: ' #f1f1f0',
      borderRadius: '0 1.25rem 1.25rem 0',
    }),attrs:{"get-container":"body","position":"left"},on:{"close":function($event){return _vm.$emit('update:closepoup', false)},"open":_vm.openpoup},model:{value:(_vm.showPoup),callback:function ($$v) {_vm.showPoup=$$v},expression:"showPoup"}},[_c('div',{staticClass:"main"},[(_vm.reader)?_c('div',[(_vm.dataInfo.book)?_c('div',{staticClass:"detail"},[_c('div',{on:{"click":function($event){return _vm.$router.push({
                path: '/detail',
                query: { book_id: _vm.dataInfo.book.book_id },
              })}}},[_c('img',{staticClass:"img",attrs:{"src":_vm.dataInfo.book.cover,"alt":_vm.dataInfo.book.name}})]),_c('div',{staticClass:"detailInfo"},[_c('div',{staticClass:"name",on:{"click":function($event){return _vm.$router.push({
                  path: '/detail',
                  query: { book_id: _vm.dataInfo.book.book_id },
                })}}},[_c('span',[_vm._v(_vm._s(_vm.dataInfo.book.name))])]),_c('div',{staticClass:"bot"},[_c('div',{staticClass:"tags"},[_c('div',{staticClass:"clearfix tagbox"},_vm._l((_vm.dataInfo.book.tag),function(item,index){return _c('div',{key:index,staticClass:"bTag fl tag"},[_vm._v(" "+_vm._s(item.tab)+" ")])}),0)]),_c('div',{on:{"click":_vm.changeOrder}},[(_vm.order_by === 1)?_c('img',{attrs:{"src":require("img/detail/jiangxu.png"),"alt":""}}):_c('img',{attrs:{"src":require("img/detail/shengxu.png"),"alt":""}})])])])]):_vm._e()]):_c('div',{staticClass:"contentsTitle"},[_c('h1',[_vm._v("Contents")]),_c('div',{on:{"click":_vm.changeOrder}},[(_vm.order_by === 1)?_c('img',{attrs:{"src":require("img/detail/jiangxu.png"),"alt":""}}):_c('img',{attrs:{"src":require("img/detail/shengxu.png"),"alt":""}})])]),_c('van-list',{attrs:{"finished":_vm.finished,"finished-text":"No more data","loading-text":"Loading","immediate-check":false,"error":_vm.error,"error-text":"Request failed, click reload"},on:{"update:error":function($event){_vm.error=$event},"load":_vm.onLoad},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},_vm._l((_vm.chapterList),function(item,index){return _c('div',{key:index,staticClass:"itemBox",class:item.is_preview === 1 ? 'lockColor' : 'openColor',on:{"click":function($event){return _vm.jumpToReader(item.chapter_id)}}},[_c('h4',{class:_vm.defaultActive(item.chapter_id, _vm.chapterList[0].chapter_id)
                ? 'active'
                : ''},[_vm._v(" "+_vm._s(item.chapter_title)+" ")]),(item.is_preview === 1)?_c('i',{staticClass:"iconfont icon-password"}):_vm._e()])}),0)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }